import { useEffect, useState } from "react";
import { ApiEndpoint, ApiService } from "../../service/api.service";
import Build from '../../asset/img/artwork/create.png';
import { FormTextArea } from "../../component/form/textarea";

interface BuildRequest {
  [key: string]: string
  title: string
  synopsis: string
  category: string
}

export enum LocalStorage {
  BuildRequest = 'build-request',
  BuildData = 'build-data',
}

let initialized = false;
export function BuildProjectPage() {
  const [buildData, setBuildDate] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLocalData, setIsLoadingLocalData] = useState<boolean>(true);
  
  useEffect(() => {
    if (!initialized) {
      loadSavedData();
      initialized = true;
    }

    return () => { initialized = false }
  }, []);

  useEffect(() => {
    if (isLoadingLocalData) return;
    localStorage.setItem(LocalStorage.BuildData, JSON.stringify(buildData));
    localStorage.removeItem(LocalStorage.BuildRequest);
  }, [buildData]);

  async function loadSavedData() {
    const localRefineRequest = localStorage.getItem(LocalStorage.BuildRequest);
    const localRefineData = localStorage.getItem(LocalStorage.BuildData);
    if (localRefineRequest) {
      try {
        const parsedLocalRefineRequest: BuildRequest = JSON.parse(localRefineRequest);
        if (parsedLocalRefineRequest) {
          generateBuildData(parsedLocalRefineRequest);
        }
      } catch (err) {}
    } else if (localRefineData) {
      setBuildDate(JSON.parse(localRefineData));
    }

    setIsLoadingLocalData(false);
  }

  async function generateBuildData(data: BuildRequest) {
    setIsLoading(true);
    const result = await ApiService.post<any, string>(ApiEndpoint.PROJECT_BUILD, data);
    setBuildDate(result);
    setIsLoading(false);
  }

  return (
    <section>

      <div className="box-brown-outer left mb-10">
        <div className="box-brown">
          <div className="left mr-10">
            <img src={ Build } width={200} alt="Artwork depicting creation" />
          </div>
          <div className="right">
            <h2>Build</h2>
            <p>Turn your refined concepts into a complete script or narrative masterpiece. Access advanced features for scriptwriting, storyboarding, and formatting. Collaborate with team members, get expert feedback, and bring your creative vision to fruition.</p>
          </div>
        </div>
      </div>

      {buildData && !isLoading && (
        <>
          <div className="box-primary-outer">
            {/* <div className="box-primary">
              <h3>{ buildData.title }</h3>
              <p>{ buildData.synopsis }</p>
            </div> */}
            <div className="box-primary">
              <p><em>Your draft script awaits your magical touch. It's like having a blank canvas ready for your strokes of genius. Take this opportunity to dance with your imagination, fill in the missing pieces, and weave a story that'll send minds swirling through another dimension.</em></p>
              <div className="btn-group btn-group-align-right btn-dull-text">
                  <button className="btn btn-sm btn-red">
                    Delete</button>
                  <button className="btn btn-sm btn-gold">
                    Save</button>
              </div>
            </div>
          </div>

          <form className="box-primary-row mt-20 mb-20" autoComplete="off">
            <FormTextArea
              id="draft"
              label="Draft"
              hint="Your generated script in Final Draft format"
              className="flex-1"
              defaultValue={ buildData }
              autoResize={ true }
            />
          </form>
        </>
      )}

      { isLoading && (
        <div className="box-primary-outer">
          <div className="box-primary">
            <h3>Your idea is being built...</h3>
            <p>We're working on building your idea. It can take upto a few minutes. Thank you for your patience.</p>
          </div>
        </div>
      )}

    </section>
  );
}
