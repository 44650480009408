import { useAuth0 } from "@auth0/auth0-react";
import { ReactElement, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from '../asset/img/logo.png';

const Links = [
    { label: 'Home', path: '/' },
    { label: 'Features', path: '/features' },
    { label: 'Pricing', path: '/pricing' },
    { label: 'Contact', path: '/contact' },
];

export function Header() {

    const { loginWithPopup, isAuthenticated, isLoading, logout, error } = useAuth0();
    const location = useLocation()

    useEffect(() => {
        if (error) {
            console.warn('Authentication Failure', error)
        }
    }, [error]);

    const renderAuthButtons = () => {
        if (isLoading) {
            return <a href="#" className="btn btn-nav btn-blue btn-blue-border">...</a>;
        }
        if (isAuthenticated) {
            return <a href="#" onClick={ () => logout() } className="btn btn-nav btn-blue btn-blue-border">Logout</a>;
        } else {
            return <a href="#" onClick={ () => loginWithPopup() } className="btn btn-nav btn-blue btn-blue-border">Login</a>
        }
    }

    const renderLinks = (): ReactElement[] => {
        return Links.map(link => renderLink(link.label, link.path));
    }

    const renderLink = (label: string, path: string): ReactElement => {
        const activeClassName = 'btn btn-nav btn-pink btn-pink-border';
        const defaultClassName = 'btn btn-nav btn-blue btn-blue-border';
        const className = location.pathname === path ? activeClassName : defaultClassName;
        return <Link key={ path } to={ path } className={ className }>{ label }</Link>
    }

    return (
        <header className="header">
            <Link to="/"><img src={Logo} alt="ScriptSmithy Logo"></img></Link>
            <nav className="desktop">
                { renderLinks() }
                { renderAuthButtons() }
            </nav>
            <nav className="mobile">
                <a href="#">&#9776;</a>
            </nav>
        </header>
    );
}
