import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useEffect, useState } from "react";
import { AuthService } from "../service/auth.service";

export function Auth(props: PropsWithChildren) {
  const { isAuthenticated, getAccessTokenSilently, isLoading: isAuth0Loading, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);

  const init = async () => {
    if (!isAuthenticated) {
      AuthService.logout();
      setIsLoading(false);
      return;
    }
    const token = await getAccessTokenSilently();
    if (token && user) {
      AuthService.setAccessToken(token);
      AuthService.setUser(user);
    } else {
      AuthService.logout();
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (!isAuth0Loading) {
      init();
    }
  }, [isAuth0Loading]);

  return (
    <>
    { isLoading ? <div></div> : props.children }
    </>
  );
}