import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <section className="hero">
      <h1 className="hero-title">404 Not Found</h1>
      <p className="hero-description">Sorry, this page does not exist</p>
      <Link to="/" className="btn btn-hero btn-pink">Back to home</Link>
    </section>
  );
}
