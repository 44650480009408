import { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  id: string;
  label: string;
  hint?: string;
  isRequired?: boolean;
  defaultValue?: string;
}

export function FormTextInput(props: Props) {

  return (
    <div className="box-primary">
      <label>
        { props.label }
        { props.isRequired ? <span className="required">*</span> : ''}
      </label>
      { props.hint && <p className="hint">{ props.hint }</p> }
      <input
        type="text"
        name={ props.id }
        placeholder={ !props.isRequired ? 'Optional' : '' }
        aria-label={ props.label }
        defaultValue={ props.defaultValue }
      />
    </div>
  )
}
