import React, { CSSProperties } from 'react';

import BkgMobile from '../asset/img/bkg/mobile.jpg';
import BkgTablet from '../asset/img/bkg/tablet.jpg';
import BkgDesktop from '../asset/img/bkg/desktop.jpg';

export function Background() {
  return (
    <picture style={ style }>
      <source media="(max-width: 480px)" srcSet={ BkgMobile } />
      <source media="(max-width: 768px)" srcSet={ BkgTablet } />
      <source media="(max-width: 1024px)" srcSet={ BkgDesktop } />
      <img src={ BkgDesktop } alt="An enchanting fantasy scene of a tree with a inviting glowing door" />
    </picture>
  )
}
const style: CSSProperties = {
  pointerEvents: 'none',
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: -1,
  overflow: 'hidden'
}
