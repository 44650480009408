import { PropsWithChildren, useState } from "react";

interface Props extends PropsWithChildren {
  title: string;
  text?: string;
  collapsed?: boolean;
  className?: string;
}

export function Accordion(props: Props) {

  const [collapsed, setCollapsed] = useState(props.collapsed);

  const getClassName = (): string => {
    return ['accordion', props.className, collapsed ? 'collapsed' : undefined]
      .filter(c => !!c)
      .join(' ');
  }

  const onClick = () => {
    setCollapsed(!collapsed);
  }

  const onContainerClick = () => {
    if (collapsed) {
      onClick();
    }
  }

  return (
    <div className={ getClassName() } onClick={ onContainerClick }>
      <h3 onClick={ onClick }>{ props.title }</h3>
      { props.text && <p>{ props.text }</p> }
      { !collapsed && props.children }
    </div>
  );
}