import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

export function HomePage() {


  const [email, setEmail] = useState('');
  const [signingUp, setSigningUp] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const handleSignUp = () => {
    setSigningUp(true);
    setTimeout(() => {
      setSigningUp(false);
      setSignedUp(true);
    }, 2000);
  };

  return (
    <section className="hero">
      <h1 className="hero-title">From Idea to Script, at Warp Speed</h1>
      <p className="hero-description">Need to write a screenplay at the speed of light? Look no further than ScriptSmithy! With this AI-powered platform, you'll be blasting through the writing process at warp speed. Don't let the complexities of screenwriting slow you down. Let ScriptSmithy be your trusty guide, as you navigate the vast expanse of your imagination to bring your story to life in record time.</p>
      <Link to="/project/new" className="btn btn-hero btn-pink">Start Writing &#10230;</Link>
      <div className="email-signup-section">
        {!signedUp ? (
          <div>
            <h2 >Full version coming soon</h2>
            <p >Sign up to stay updated and receive exclusive launch discounts</p>
            <div className="signup-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                disabled={signingUp}
              />
              <button
                onClick={handleSignUp}
                className={`${signingUp ? 'disabled' : ''} 'btn btn-nav btn-blue btn-blue-border'`}
                disabled={signingUp}
              >
                {signingUp ? 'Signing Up...' : 'Sign Up'}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2>Thank you for signing up for updates!</h2>
          </div>
        )}
      </div>
    </section>
  );
}
