import { useEffect, useState } from 'react';
import Inspire from '../../asset/img/artwork/inspire.png';
import { FormContainer } from '../../component/form/container';
import { FormSelect, FormSelectOption } from '../../component/form/select';
import { FormTextArea } from '../../component/form/textarea';
import { ApiEndpoint, ApiService } from '../../service/api.service';
import { FormTextInput } from '../../component/form/textinput';
import { ICategory, IQuestion } from '../../interface/category.interface';
import { useNavigate } from 'react-router-dom';

interface FormData {
  scriptType?: string;
  premise?: string;
}

interface Idea {
  title: string;
  synopsis: string;
}

export enum LocalStorage {
  Shortlist = 'shortlist',
  RefineRequest = 'refine-request',
}

export enum ViewState {
  Ideas = 'ideas',
  Shortlist = 'shortlist',
}

export function InspireProjectPage() {

  const [viewState, setViewState] = useState<ViewState>(ViewState.Ideas);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLocalData, setIsLoadingLocalData] = useState<boolean>(true);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [questions, setQuestions] = useState<IQuestion[]>();
  const [ideas, setIdeas] = useState<Idea[]>([])
  const [shortlist, setShortlist] = useState<Idea[]>([])
  const [formData, setFormData] = useState<FormData>();

  const navigate = useNavigate();

  useEffect(() => {
    loadCategories();
    loadSavedData();
  }, []);

  useEffect(() => {
    saveShortlist();
  }, [shortlist]);

  async function loadCategories() {
    const result = await ApiService.get<ICategory[]>(ApiEndpoint.PROJECT_CATEGORIES);
    setCategories(result || []);
  }

  async function loadSavedData() {
    const localShortlist = localStorage.getItem(LocalStorage.Shortlist);
    if (localShortlist) {
      try {
        const parsedLocalShortlist = JSON.parse(localShortlist);
        if (parsedLocalShortlist) {
          setShortlist(parsedLocalShortlist);
        }
      } catch (err) {}
    }

    setIsLoadingLocalData(false);
  }

  async function onCategorySelect(option: FormSelectOption) {
    const result = await ApiService.get<IQuestion[]>(ApiEndpoint.PROJECT_QUESTIONS, [ option.id, 'inspire' ]);
    setQuestions(result);
  }

  async function onSubmit(data: FormData) {
    setIdeas([]);
    setIsLoading(true);
    setViewState(ViewState.Ideas);
    const response = await ApiService.post<FormData, Idea[]>(ApiEndpoint.PROJECT_INSPIRE, data);
    if (response) {
      setFormData(data);
      setIdeas(response);
    }
    setIsLoading(false);
  }

  function getCategoryOptions() {
    const options = [];
    for (let category of categories) {
      options.push({ id: category.id, label: category.title });
    }
    
    return options;
  }

  function generateQuestionComponents() {
    if (!questions || !questions.length) {
      return ;
    }
    
    return questions.map((question: any) => {
      if (question.type === 'select') {
        return <FormSelect
          key={question.id}
          id={ question.id }
          label={ question.title }
          hint={ question.text }
          options={ question.options.map((option: string, i: number) => ({ id: option, label: option })) }
        />
      } else if (question.type === 'textarea') {
        return <FormTextArea
          key={question.id}
          id={ question.id }
          label={ question.title }
          hint={ question.text }
        />
      } else if (question.type === 'textinput') {
        return <FormTextInput
          key={question.id}
          id={ question.id }
          label={ question.title }
          hint={ question.text }
        />
      }
      return <></>;
    })
  }

  function saveShortlist() {
    if (!isLoadingLocalData) {
      localStorage.setItem(LocalStorage.Shortlist, JSON.stringify(shortlist));
    }
  }

  function deleteIdea(idea: Idea) {
    setIdeas(ideas?.filter(i => i.title !== idea.title));
  }

  async function moreLikethisIdea(idea: Idea) {
    setIdeas([]);
    setIsLoading(true);
    setViewState(ViewState.Ideas);
    const response = await ApiService.post<Idea & FormData, Idea[]>(ApiEndpoint.PROJECT_INSPIRE_SIMILAR,
      { ...idea, ...formData });
    if (response) {
      setIdeas(response);
    }
    setIsLoading(false);
  }

  function shortListIdea(idea: Idea) {
    setShortlist(shortlist.concat(idea));
    setIdeas(ideas.filter(i => i.title !== idea.title));
  }

  function deleteIdeaFromShortlist(idea: Idea) {
    setShortlist(shortlist.filter(i => i.title !== idea.title));
    setIdeas(ideas.concat(idea));
  }

  async function selectIdea(idea: Idea) {
    localStorage.setItem(LocalStorage.RefineRequest, JSON.stringify({ ...formData, ...idea, generated: false }))
    navigate('/project/refine');
  }

  return (
    <section>

      <div className="box-brown-outer left mb-10">
        <div className="box-brown">
          <div className="left">
            <img src={ Inspire } width={200} alt="Artwork depicting inspiration" />
          </div>
          <div className="right">
            <h2>Get Inspired</h2>
            <p>Let&apos;s get you Inspired! We&apos;ll help you generate ideas and inspiration for your next project. By answering a few questions, we&apos;ll be able to narrow down the type of script you&apos;re looking for. From there, we&apos;ll provide you with a range of ideas to get your creative juices flowing.</p>
          </div>
        </div>
      </div>

      <div className="box-primary-row">

        <FormContainer<FormData>
          buttonLabel="Generate Ideas"
          buttonLoadingLabel="Generating..."
          isLoading={ isLoading }
          onSubmit={ onSubmit }>

          <FormSelect
            id="category"
            label="Script Type"
            hint="What type of script are you looking for?"
            isRequired={ true }
            autoFocus={ true }
            options={ getCategoryOptions() }
            onSelect={ onCategorySelect }
            />

            {generateQuestionComponents()}

        </FormContainer>

        <div className="box-primary-outer right flex-4">
          <div className="tab-group">
            <button className={`tab ${viewState === ViewState.Ideas ? 'selected' : ''}`}
              onClick={ () => setViewState(ViewState.Ideas) }>
              Ideas ({ideas.length})</button>
            <button className={`tab ${viewState === ViewState.Shortlist ? 'selected' : ''}`}
              onClick={ () => setViewState(ViewState.Shortlist) }>
              Shortlist ({shortlist.length})</button>
          </div>
          {isLoading && (
            <div className="box-primary">
              <h3>Ideas are being generated...</h3>
              <p>We're working on generating fresh ideas for you. It can take upto 15 seconds. Thank you for your patience.</p>
            </div>
          )}
          {viewState === ViewState.Ideas && !!ideas && !!ideas.length && (
            <>
            {ideas.map(idea =>
              <div key={ idea.title } className="box-primary synopsis">
                <h3>{ idea.title }</h3>
                <p>{ idea.synopsis }</p>
                <div className="btn-group btn-group-align-right btn-dull-text">
                    <button className="btn btn-sm btn-red" onClick={ () => deleteIdea(idea) }>
                      Delete</button>
                    <button className="btn btn-sm btn-blue" onClick={ () => moreLikethisIdea(idea) }>
                      More Like This</button>
                    <button className="btn btn-sm btn-gold" onClick={ () => shortListIdea(idea) }>
                      Shortlist</button>
                    <button className="btn btn-sm btn-pink" onClick={ () => selectIdea(idea) }>
                      Select &#10230;</button>
                </div>
              </div>
            )}
            </>
          )}
          {viewState === ViewState.Shortlist && !!shortlist && !!shortlist.length && (
            <>
            {shortlist.map(idea =>
              <div key={ idea.title } className="box-primary synopsis">
                <h3>{ idea.title }</h3>
                <p>{ idea.synopsis }</p>
                <div className="btn-group btn-group-align-right btn-dull-text">
                    <button className="btn btn-sm btn-red" onClick={ () => deleteIdeaFromShortlist(idea) }>
                      Delete</button>
                    <button className="btn btn-sm btn-blue" onClick={ () => moreLikethisIdea(idea) }>
                      More Like This</button>
                    <button className="btn btn-sm btn-pink" onClick={ () => selectIdea(idea) }>
                      Select &#10230;</button>
                </div>
              </div>
            )}
            </>
          )}
          {!isLoading && !ideas && (
            <div className="box-primary">
              <h3>&#10229; Answer These Questions</h3>
              <p>We're excited to work with you on your creative project! To ensure that we fully understand your vision, we'll be asking you a series of questions that will help us gather all the necessary information. These questions will cover different aspects of your project, including its format, tone, and mood.</p>
              <p>Thank you for entrusting us with your project, and we look forward to creating something amazing together!</p>
            </div>
          )}

        </div>

      </div>

    </section>
  );
}
