import { User } from "@auth0/auth0-react";
import React, { ReactElement, useEffect, useState } from "react";

let user: User;
let pricingTable: ReactElement;

export function PricingPage () {

  const [isInitialized, setIsInitialized] = useState(false);
  
  useEffect(() => {

    const userString = localStorage.getItem('user');
    if (userString) {
      user = JSON.parse(userString);
      console.log(user)
    } else {
      console.log('No User')
    }

    pricingTable = React.createElement("stripe-pricing-table", {
      "pricing-table-id": 'prctbl_1NAdmnEACS4S5fqOriQKY9yd',
      "publishable-key": 'pk_live_51IKJqNEACS4S5fqOwtBivcAM7BbwbDipWYZeGLoLPaYlb4cvOzDLihBznqZ7dPugo1WlfcY7lEJmTvSUD1fJtzIj00ppKkHaFL',
      "client-reference-id": user?.sub,
      "customer-email": user?.email
    })

    setIsInitialized(true);

    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };

  }, []);

  return isInitialized ? pricingTable : <></>;
};
