import { useEffect, useState } from 'react';
import Refine from '../../asset/img/artwork/refine.png';
import { FormTextArea } from '../../component/form/textarea';
import { FormTextInput } from '../../component/form/textinput';
import { ApiEndpoint, ApiService } from '../../service/api.service';
import { FormSelect } from '../../component/form/select';
import { Accordion } from '../../component/accordion';
import { FormContainer } from '../../component/form/container';
import { useNavigate } from 'react-router-dom';

interface QuestionBase {
  id: string;
  type: 'textinput' | 'textarea' | 'select' | 'object' | 'array';
  title: string;
  text?: string;
}

interface TextInputQuestion extends QuestionBase {
  answer: string;
  type: 'textinput';
}

interface TextAreaQuestion extends QuestionBase {
  answer: string;
  type: 'textarea';
}

interface SelectQuestion extends QuestionBase {
  answer: string;
  type: 'select';
  options: string[];
}

interface ObjectQuestion extends QuestionBase {
  answer: string;
  type: 'object';
  items: ( TextInputQuestion | TextAreaQuestion )[];
}

interface ArrayQuestion extends QuestionBase {
  answer: string;
  type: 'array';
  items: Array<Array<TextInputQuestion | TextAreaQuestion>>;
}

interface RefineRequest {
  title: string;
  synopsis: string;
  generated: boolean;
}

type RefineQuestion = TextInputQuestion | TextAreaQuestion | SelectQuestion | ObjectQuestion | ArrayQuestion;
type RefineData = {
  title: string;
  synopsis: string;
  answers: RefineQuestion[][];
}

export enum LocalStorage {
  RefineRequest = 'refine-request',
  RefineData = 'refine-data',
  BuildData = 'build-request',
}

let initialized = false;
export function RefineProjectPage() {

  const [refineData, setRefineData] = useState<RefineData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingLocalData, setIsLoadingLocalData] = useState<boolean>(true);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!initialized) {
      loadSavedData();
      initialized = true;
    }

    return () => { initialized = false }
  }, []);

  useEffect(() => {
    if (isLoadingLocalData) return;
    localStorage.setItem(LocalStorage.RefineData, JSON.stringify(refineData));
    localStorage.removeItem(LocalStorage.RefineRequest);
  }, [refineData]);

  async function loadSavedData() {
    const localRefineRequest = localStorage.getItem(LocalStorage.RefineRequest);
    const localRefineData = localStorage.getItem(LocalStorage.RefineData);
    if (localRefineRequest) {
      try {
        const parsedLocalRefineRequest: RefineRequest = JSON.parse(localRefineRequest);
        if (parsedLocalRefineRequest) {
          generateRefineData(parsedLocalRefineRequest);
        }
      } catch (err) {}
    } else if (localRefineData) {
      setRefineData(JSON.parse(localRefineData));
    }

    setIsLoadingLocalData(false);
  }

  async function generateRefineData(data: RefineRequest) {
    setIsLoading(true);
    const response = await ApiService.post<RefineRequest, RefineData>(ApiEndpoint.PROJECT_REFINE, data);
    if (response) {
      setRefineData(response);
    }
    setIsLoading(false);
  }

  function generateQuestionComponents() {
    if (!refineData || !refineData.answers) {
      return <></>;
    }
    
    return refineData.answers
      .map((viewOrder: RefineQuestion[], i: number) => (
        <div key={ i } className="box-primary-outer right flex-4">
          { viewOrder.map((question: RefineQuestion) =>
            generateQuestionComponent(question)) }
        </div>
      ))
  
  }

  function generateQuestionComponent(question: RefineQuestion) {
    switch (question.type) {
      case 'textinput':   return generateTextInputComponent(question);
      case 'textarea':    return generateTextAreaComponent(question);
      case 'select':      return generateSelectComponent(question);
      case 'object':      return generateObjectComponent(question);
      case 'array':       return generateArrayComponent(question);
      default:            return <></>;
    }
  }

  function generateTextInputComponent(question: RefineQuestion): JSX.Element {
    return <FormTextInput
      key={question.id}
      id={ question.id }
      label={ question.title }
      hint={ question.text }
      defaultValue={ question.answer }
    />
  }

  function generateTextAreaComponent(question: RefineQuestion): JSX.Element {
    return <FormTextArea
      key={question.id}
      id={ question.id }
      label={ question.title }
      hint={ question.text }
      defaultValue={ question.answer }
      autoResize={ true }
    />
  }

  function generateSelectComponent(question: SelectQuestion): JSX.Element {
    return <FormSelect
      key={question.id}
      id={ question.id }
      label={ question.title }
      hint={ question.text }
      defaultValue={ question.answer }
      options={ question.options.map(option => ({ id: option, label: option })) }
    />
  }

  function generateObjectComponent(questions: ObjectQuestion): JSX.Element {
    return <Accordion
      key={ questions.id }
      className="box-primary-outer"
      collapsed={ true }
      title={ questions.title }
      text={ questions.text }>
      <div className="box-primary-collapsible">
        { questions.items.map(question => {
          switch (question.type) {
            case 'textinput':   return generateTextInputComponent(question);
            case 'textarea':    return generateTextAreaComponent(question);
            default:            return <></>;
          }})
        }
      </div>
    </Accordion>
  }

  function generateArrayComponent(questions: ArrayQuestion): JSX.Element {
    return <Accordion
      key={ questions.id }
      className="box-primary-outer"
      collapsed={ true }
      title={ questions.title }
      text={ questions.text }>
    { questions.items.map((question, i: number) => {
      return <div className="box-primary" key={ i }>
        { Array.isArray(question) ? question.map(_question => {
          switch (_question.type) {
            case 'textinput':   return generateTextInputComponent(_question);
            case 'textarea':    return generateTextAreaComponent(_question);
            default:            return <></>;
          }
        }) : (
          (question as TextInputQuestion | TextAreaQuestion).type === 'textinput' ?
            generateTextInputComponent(question) : generateTextAreaComponent(question)
        )}
      </div>
      })
    }
    </Accordion>
  }

  async function build() {
    if (!refineData) return;

    const { answers, ...data } = refineData
    // @ts-ignore
    delete data.generated;

    for (let answer of answers.flat()) {
      // @ts-ignore
      data[answer.title] = answer.answer;
    }
    
    localStorage.setItem(LocalStorage.BuildData, JSON.stringify(data));
    navigate('/project/build');
  }

  return (
    <section>

      <div className="box-brown-outer left mb-10">
        <div className="box-brown">
          <div className="left">
            <img src={ Refine } width={200} alt="Artwork depicting refinement" />
          </div>
          <div className="right">
            <h2>Refine</h2>
            <p>Refine your ideas and bring your characters and settings to life with simple questions designed to help you define your story. Get one step closer to your finished script with our Refine stage.</p>
          </div>
        </div>
      </div>

      {refineData && !isLoading && (
        <>
          <div className="box-primary-outer">
            <div className="box-primary">
              <h3>{ refineData.title }</h3>
              <p>{ refineData.synopsis }</p>
            </div>
            <div className="box-primary">
              <p><em>You've got a fantastic starting point with all this groovy generated info about your story. Characters, motivations, goals, and even the lowdown on those key settings. Now it's your turn to hop in, tinker, and jazz it up. Let your imagination run wild and build a mind-blowing tale that'll leave folks gobsmacked.</em></p>
              <div className="btn-group btn-group-align-right btn-dull-text">
                  <button className="btn btn-sm btn-red">
                    Delete</button>
                  <button className="btn btn-sm btn-gold">
                    Save</button>
                  <button className="btn btn-sm btn-pink" onClick={ build }> Build &#10230;</button>
              </div>
            </div>
          </div>

          <form className="box-primary-row" autoComplete="off">
            { generateQuestionComponents() }
          </form>
        </>
      )}

      { !refineData && !isLoading && (
        <div className="box-primary-row">

          <FormContainer<FormData>
            buttonLabel="Generate Ideas"
            buttonLoadingLabel="Generating..."
            isLoading={ isLoading }
            onSubmit={ () => {} }>

            {/* <FormSelect
              id="category"
              label="Script Type"
              hint="What type of script are you looking for?"
              isRequired={ true }
              autoFocus={ true }
              options={ getCategoryOptions() }
              onSelect={ onCategorySelect }
              /> */}

              {generateQuestionComponents()}

          </FormContainer>

        </div>
      )}

      { isLoading && (
        <div className="box-primary-outer">
          <div className="box-primary">
            <h3>Your idea is being refined...</h3>
            <p>We're working on refining your idea. It can take upto a minute. Thank you for your patience.</p>
          </div>
        </div>
      )}

    </section>
  );
}
