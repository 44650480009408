import { FormEvent, PropsWithChildren } from "react";

interface Props<T> extends PropsWithChildren {
  buttonLabel: string;
  buttonLoadingLabel: string;
  isLoading?: boolean;
  onSubmit?: (data: T) => void;
}

export function FormContainer<T>(props: Props<T>) {

 function onSubmit(e: FormEvent) {
  e.preventDefault();

  const data: any = {};

  const formData = new FormData(e.currentTarget as HTMLFormElement)
  for (let [key, value] of Array.from(formData.entries())) {
    data[key] = value.toString()
  }

  if (props.onSubmit) {
    props.onSubmit(data as T);
  }

 }

  return (
    <form className="box-primary-outer mr-10 flex-2" onSubmit={ onSubmit }>
      { props.children }
      <div className="btn-group">
        <button type="submit"
          className="btn btn-pink"
          disabled={props.isLoading}
        >{!props.isLoading ? props.buttonLabel : props.buttonLoadingLabel}</button>
      </div>
    </form>
  );
}
