import { Link } from 'react-router-dom';
import Create from '../../asset/img/artwork/create.png';
import Inspire from '../../asset/img/artwork/inspire.png';
import Refine from '../../asset/img/artwork/refine.png';

export function NewProjectPage() {
  return (
    <section>

      <div className="box-black">
        <h2>Start New Project</h2>
        <p>When starting a new project, it can be helpful to break it down into different stages. Which stage best describes where you're at with your project right now? Are you still looking for ideas and inspiration, working on fleshing out your initial idea, or ready to dive in and start creating?</p>
      </div>

      <div className="box-black-row">

        <Link to="/project/inspire" className="box-black highlighted">
          <div>
            <h3>Inspire</h3>
            <img src={ Inspire } width={200} height={220} alt="Artwork depicting inspiration" />
            <div className="box-black-hint">
              <p>I don&apos;t know</p>
              <p>Give me some ideas</p>
            </div>
            <button className='btn btn-pink'>New Project</button>
          </div>
        </Link>

        <Link to="/project/refine" className="box-black highlighted">
          <div>
            <h3>Refine</h3>
            <img src={ Refine } width={200} height={220} alt="Artwork depicting refinement" />
            <div className="box-black-hint">
              <p>I have a general idea</p>
              <p>Help me refine it</p>
            </div>
            <button className='btn btn-pink'>New Project</button>
          </div>
        </Link>

        <Link to="/" className="box-black highlighted">
          <div>
            <h3>Create</h3>
            <img src={ Create } width={200} height={220} alt="Artwork depicting creation" />
            <div className="box-black-hint">
              <p>I know exactly what I want</p>
              <p>Help me build it</p>
            </div>
            <button className='btn btn-pink'>New Project</button>
          </div>
        </Link>

      </div>

    </section>
  )
}
