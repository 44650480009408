import { PropsWithChildren, SyntheticEvent } from "react";

export interface FormSelectOption {
  id: string;
  label: string;
  disabled?: boolean;
}

interface Props extends PropsWithChildren {
  id: string;
  label: string;
  hint?: string;
  mode?: 'autocomplete' | 'select';
  isRequired?: boolean;
  autoFocus?: boolean;
  options: FormSelectOption[];
  defaultValue?: string;
  onSelect?: (option: FormSelectOption) => void;
}

export function FormSelect(props: Props) {

  const mode = props.mode || 'select';

  function onSelect(e: SyntheticEvent<HTMLSelectElement, Event>) {
    if (props.onSelect) {
      props.onSelect({
        id: e.currentTarget.value,
        label: e.currentTarget.textContent as string,
      });
    }
  }

  return (
    <div className="box-primary">
      <label>{ props.label }{ props.isRequired ? <span className="required">*</span> : ''}</label>
      { props.hint && <p className="hint">{ props.hint }</p> }
      { mode === 'autocomplete' && (
        <>
          <input type="text" list={ props.id } defaultValue={props.defaultValue} />
          <datalist id={ props.id }>
            { props.options.map(option => (
              <option key={ option.id } value={ option.id } disabled={ option.disabled }>{ option.label }</option>
            ))}
          </datalist>
        </>
      )}
      { mode === 'select' && (
        <select name={ props.id }
          defaultValue={ props.defaultValue }
          autoFocus={ props.autoFocus }
          onChange={ onSelect }>
          <option hidden value="">---</option>
          { props.options.map(option => (
            <option key={ option.id } value={ option.id } disabled={ option.disabled }>{ option.label }</option>
          ))}
        </select>
      )}
    </div>
  )
}
