import { PropsWithChildren, useEffect, useRef } from "react";

interface Props extends PropsWithChildren {
  id: string;
  label: string;
  hint?: string;
  rows?: number;
  isRequired?: boolean;
  className?: string;
  defaultValue?: string;
  autoResize?: boolean;
}

export function FormTextArea(props: Props) {

  const ref = useRef<HTMLTextAreaElement>(null);

  const onChange = () => {
    if (!ref.current || !props.autoResize) return;
    ref.current.style.height = 'auto';
    ref.current.style.height = ref.current?.scrollHeight + 'px';
  }

  useEffect(onChange, []);

  return (
    <div className={ `box-primary` + (props.className ? ` ${props.className}` : '') }>
      <label>
        { props.label }
        { props.isRequired ? <span className="required">*</span> : ''}
        </label>
      { props.hint && <p className="hint">{ props.hint }</p> }
      <textarea
        ref={ ref }
        name={ props.id }
        rows={ props.rows || 4 }
        placeholder={ !props.isRequired ? 'Optional' : '' }
        aria-label={ props.label }
        defaultValue={ props.defaultValue }
        onChange={ onChange }>
      </textarea>
    </div>
  )
}
