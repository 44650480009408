import { User } from "@auth0/auth0-react";

const KEY_ACCESS_TOKEN = 'accessToken';
const KEY_USER = 'user';

class _AuthService {

  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  logout() {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_USER);
  }

  setAccessToken(token: string) {
    localStorage.setItem(KEY_ACCESS_TOKEN, token);
  }

  getAccessToken() {
    return localStorage.getItem(KEY_ACCESS_TOKEN);
  }

  setUser(user: User) {
    localStorage.setItem(KEY_USER, JSON.stringify(user));
  }

  getUser(): User | undefined {
    const userString = localStorage.getItem(KEY_USER);
    if (!userString) {
      return undefined;
    }
    try {
      return JSON.parse(userString);
    } catch (err) {
      console.warn('Failed to parse user string', userString);
      return undefined;
    }
  }

}

export const AuthService = new _AuthService();
