import React from "react";
import IconFB from '../asset/img/icon/fb.png';
import IconIG from '../asset/img/icon/ig.png';
import IconTW from '../asset/img/icon/tw.png';

export function Footer() {
  return (
    <footer className="footer">
      <div>
        <a href="#" className="btn-footer-social" aria-label="Link to our Facebook page">
          <img src={ IconFB } width={32} height={32} alt="Our Facebook page"></img></a>
        <a href="#" className="btn-footer-social" aria-label="Link to our Instagram page">
          <img src={ IconIG } width={32} height={32} alt="Our Instagram page"></img></a>
        <a href="#" className="btn-footer-social" aria-label="Link to our Twitter account">
          <img src={ IconTW } width={32} height={32} alt="Our Twitter account"></img></a>
      </div>
      <div>
        <a href="#" className="btn-footer">Privacy Policy</a>
        <a href="#" className="btn-footer">Terms &amp; Conditions</a>
      </div>
    </footer>
  );
}
